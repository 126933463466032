import React from 'react';
import styles from '../../styles/component-styles/Industry-Jobs.module.css';
import ContactUsButton from '../../components/Buttons/ContactUsButton.js';

const BusinessTalent = () => {
    return (
        <div className={styles.jobsContainer}>
            <h2>Discover Top Executive Talent for Your Business with Apex Staff Global.</h2>
            <p>At Apex Staff Global, we specialize in connecting leading companies with exceptional business executives. Whether your organization is a dynamic startup or a global corporation, our platform offers access to a diverse talent pool of experienced leaders ready to elevate your business. Our extensive network comprises professionals across various industries, ensuring you find the perfect candidate for your executive roles.</p>
            <p>Partnering with Apex Staff Global offers you:</p>
            <ul>
                <li>A dedicated team committed to finding the ideal executive match for your company’s unique needs.</li>
                <li>Expert guidance and support throughout the recruitment process, from search to selection.</li>
                <li>Access to resources and tools designed to enhance your reach and engagement with top talent.</li>
            </ul>
            <p>Enhance your leadership team with a strategic hire. Join our exclusive network of businesses seeking the best in executive talent today!</p>
            <ContactUsButton />
        </div>
    );
};

export default BusinessTalent;
