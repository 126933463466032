// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Industries from './pages/Industries';
import AboutUs from './pages/AboutUs';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import ContactUs from './pages/ContactUs';
import NoMatch from './pages/NoMatch';
import { UserProvider } from './context/UserContext';
import { NavbarProvider } from './context/NavbarContext';
import UseScrollToTop from './UseScrollToTop';
import './App.css';
import { FindJobRoutes } from './components/FindJobRoutes';
import { FindTalentRoutes } from './components/FindTalentRoutes';
import SalesRep from './pages/SalesRep';
import ProjectManagerIntern from './pages/Project-manager-intern';
import AdminDashboard from './pages/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import PostJob from './pages/PostJob';
import JobDetail from './pages/JobDetail';
import AllJobs from './pages/AllJobs';
import EditJob from './pages/EditJob';
import Careers from './pages/Careers';
import Profile from './pages/Profile';

function App() {
  return (
    <UserProvider>
      <NavbarProvider>
        <Router>
          <UseScrollToTop />
          <div className="App">
            <div className="App-content">
              <NavBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/find-job/*" element={<FindJobRoutes />} />
                <Route path="/find-talent/*" element={<FindTalentRoutes />} />
                <Route path="/industries" element={<Industries />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/sales-rep" element={<SalesRep />} />
                <Route path="/project-manager-internship" element={<ProjectManagerIntern />} />
                <Route path="/admin/*" element={<ProtectedRoute element={<AdminDashboard />} adminOnly={true} />} />
                <Route path="/admin" element={<ProtectedRoute element={<AdminDashboard />} adminOnly={true} />} />
                <Route path="/admin/post-job" element={<ProtectedRoute element={<PostJob />} adminOnly={true} />} />
                <Route path="/jobs/:jobId" element={<JobDetail />} />
                <Route path="/admin/all-jobs" element={<ProtectedRoute element={<AllJobs />} adminOnly={true} />} />
                <Route path="/edit-job/:jobId" element={<ProtectedRoute element={<EditJob />} />} adminOnly={true}/>
                <Route path="/careers" element={<Careers />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </NavbarProvider>
    </UserProvider>
  );
}

export default App;
