import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import styles from '../styles/AdminDashboard.module.css';

const AdminDashboard = () => {
    const navigate = useNavigate();
    const { user } = useUser();

    const handlePostJobClick = () => {
        navigate('/admin/post-job');
    };

    const handleViewJobsClick = () => {
        navigate('/admin/all-jobs');
    };

    return (
        <div className={styles.dashboardContainer}>
            <h1 className={styles.title}>Admin Dashboard</h1>
            <p className={styles.welcomeMessage}>Welcome, {user?.firstName || 'Admin'}!</p>
            
            <div className={styles.actionsContainer}>
                <button className={styles.actionButton} onClick={handlePostJobClick}>Post a Job</button>
                <button className={styles.actionButton} onClick={handleViewJobsClick}>View Jobs</button>
                <button className={styles.actionButton}>View Applications</button>
                <button className={styles.actionButton}>Manage Users</button>
            </div>

            <div className={styles.statsContainer}>
                <h2 className={styles.sectionTitle}>Dashboard Stats</h2>
                <ul className={styles.statsList}>
                    <li>Total Jobs Posted: 120</li>
                    <li>Total Applications: 450</li>
                    <li>Active Users: 300</li>
                </ul>
            </div>

            <div className={styles.recentActivitiesContainer}>
                <h2 className={styles.sectionTitle}>Recent Activities</h2>
                <ul className={styles.activitiesList}>
                    <li>John Doe applied for Project Manager Intern</li>
                    <li>Jane Smith posted a new job for Software Engineer</li>
                    <li>Admin updated the job description for Sales Representative</li>
                </ul>
            </div>
        </div>
    );
};

export default AdminDashboard;
