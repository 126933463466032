import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import staffPhoto from '../images/staff-photo.webp';
import styles from '../styles/Home.module.css';
import { useNavbar } from '../context/NavbarContext';

export default function Home() {
  const { closeNav } = useNavbar();
  const [isFirstMessage, setIsFirstMessage] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFirstMessage(prev => !prev);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={staffPhoto} alt="Staff" className={styles.image} />
        </div>
        <div className={styles['text-overlay']}>
          <h1 className={styles.heading}>Bridging the gap between talent and opportunity</h1>
          <div className={styles.buttonsContainer}>
            <Link to="/find-job" className={styles.button} onClick={closeNav}>FIND A JOB</Link>
            <Link to="/find-talent" className={styles.button} onClick={closeNav}>FIND TALENT</Link>
          </div>
        </div>
      </div>
      <div className={styles.contactSection}>
        <h2 className={styles.contactHeading}>Let's Connect</h2>
        <p className={`${styles.contactText} ${styles.fadeText}`}>
          {isFirstMessage 
            ? "Ready to take the next step in your career journey? We're here to help."
            : "Looking to fill your next position? Let's find your ideal candidate."}
        </p>
        <Link to="/contact-us" className={styles.contactButton}>Get in Touch</Link>
      </div>
    </>
  );
}
