import React from 'react';
import styles from '../../styles/component-styles/Industry-Jobs.module.css';
import ContactUsButton from '../../components/Buttons/ContactUsButton.js';

const DriversTalent = () => {
    return (
        <div className={styles.jobsContainer}>
            <h2>Connect with Top Driving Talent through Apex Staff Global.</h2>
            <p>At Apex Staff Global, we specialize in linking transportation companies with skilled and reliable drivers. Our platform caters to a wide range of transportation needs, from local delivery services to long-haul trucking. Whether you need experienced CDL drivers, delivery drivers, or specialized transport operators, our extensive network ensures you have access to the best talent in the industry.</p>
            <p>Partnering with Apex Staff Global offers your company:</p>
            <ul>
                <li>A dedicated team focused on understanding your specific transportation staffing needs and finding the right talent to meet those challenges.</li>
                <li>Professional guidance and support throughout the hiring process, from candidate search to final selection.</li>
                <li>Access to resources and tools designed to enhance your reach and engagement with top driving professionals.</li>
            </ul>
            <p>Strengthen your transportation team with strategic hires. Discover our elite network of driving professionals today!</p>
            <ContactUsButton />
        </div>
    );
};

export default DriversTalent;
