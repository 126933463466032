import React from 'react';
import styles from '../../styles/component-styles/Industry-Jobs.module.css';
import ContactUsButton from '../../components/Buttons/ContactUsButton.js';

const ConstructionTalent = () => {
    return (
        <div className={styles.jobsContainer}>
            <h2>Build Your Team with Premier Construction Talent through Apex Staff Global.</h2>
            <p>At Apex Staff Global, we specialize in facilitating connections between the construction industry and leading professionals. Our platform serves construction firms of all sizes, from local contractors to multinational enterprises, offering access to a vast pool of talent. Whether you're in need of experienced project managers, skilled architects, or innovative civil engineers, our network is ready to help you find the right individuals to bring your projects to life.</p>
            <p>Working with Apex Staff Global provides your firm with:</p>
            <ul>
                <li>A dedicated team that understands the construction industry's unique challenges and staffing needs.</li>
                <li>Comprehensive support throughout your recruitment process, ensuring you find candidates who fit your project requirements and company culture.</li>
                <li>Access to resources and tools that enhance your visibility among top construction professionals.</li>
            </ul>
            <p>Advance your construction projects with strategic hires. Tap into our elite network of construction talent today!</p>
            <ContactUsButton />
        </div>
    );
};

export default ConstructionTalent;
