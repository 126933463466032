import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/FindJob.module.css';
import findJobPhoto from '../images/FindJob.svg';
import IndustriesSection from '../components/IndustriesSection';

export default function FindJob() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch job postings from the backend
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs/jobs`); // Adjust the URL as necessary
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleJobClick = (jobId) => {
    navigate(`/jobs/${jobId}`);
  };

  return (
    <div className={styles.container}>
      {!imageLoaded && <div className={styles.imagePlaceholder}></div>}
      <img 
        src={findJobPhoto} 
        alt="Staff" 
        className={`${styles.image} ${imageLoaded ? styles.imageVisible : styles.imageLoading}`} 
        onLoad={() => setImageLoaded(true)}
      />

      <IndustriesSection context="find-job"/>

      {/* Recent Jobs Section */}
      <div className={styles.recentJobsSection}>
        <h2 className={styles.recentJobsTitle}>RECENT JOB POSTINGS</h2>
        <div className={styles.jobList}>
          {jobs.map((job, index) => (
            <div 
              key={index} 
              className={styles.jobItem} 
              onClick={() => handleJobClick(job._id)}
            >
              <span className={styles.jobIcon}>▶</span>
              <div className={styles.jobDetails}>
                <h3 className={styles.jobTitle}>{job.overview.title}</h3>
                <p className={styles.jobLocation}>{job.overview.location} - Posted {new Date(job.createdAt).toLocaleDateString()}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
