import React from 'react';
import { useUser } from '../../context/UserContext.js'; 
import styles from '../../styles/component-styles/Industry-Jobs.module.css'; 
import SignUpButton from '../../components/Buttons/SignUpButton.js';

const BusinessJobs = () => {
    const { user } = useUser(); 

    const renderButton = () => {
        if (user) {
            return <button className={styles.button} onClick={() => {/* Logic to handle application */}}>Apply</button>;
        } else {
            return <SignUpButton />;
        }
    };

    return (
        <div className={styles.jobsContainer}>
            <h2>Discover Your Next Business Executive Role with Apex Staff Global.</h2>
            <p>At Apex Staff Global, we excel in bridging top talent with premier business opportunities. Whether you're stepping into the executive world or you're an experienced leader, our platform showcases a diverse array of executive positions across various industries. From innovative startups to global corporations, our expansive network of companies is on the lookout for skilled professionals like you.</p>
            <p>Signing up with Apex Staff Global provides you access to:</p>
            <ul>
                <li>A devoted team focused on discovering personalized executive opportunities for you.</li>
                <li>Expert advice and dedicated support throughout your executive search and application process.</li>
                <li>Resources and tools designed to improve your visibility and connection with top employers.</li>
            </ul>
            <p>Seize your next significant career move. Become part of our elite network of business leaders today!</p>
            {/* Render the appropriate button based on the user's authentication status */}
            {renderButton()}
        </div>
    );
};

export default BusinessJobs;
