// FindJobRoutes.js
import { Route, Routes } from 'react-router-dom';
import FindJob from '../pages/FindJob';
import ConstructionJobs from '../pages/Job-Industries/ConstructionJobs'; 
import HealthcareJobs from '../pages/Job-Industries/HealthcareJobs'; 
import ItTechJobs from '../pages/Job-Industries/ItTechJobs'; 
import BusinessJobs from '../pages/Job-Industries/BusinessJobs';
import DriversJobs from '../pages/Job-Industries/DriversJobs';

const FindJobRoutes = () => {
  return (
    <Routes>
      <Route index element={<FindJob />} />
      <Route path="construction" element={<ConstructionJobs />} />
      <Route path="healthcare" element={<HealthcareJobs />} />
      <Route path="it-tech" element={<ItTechJobs />} />
      <Route path="business" element={<BusinessJobs />} />
      <Route path="drivers" element={<DriversJobs />} />
    </Routes>
  );
};

export { FindJobRoutes };
