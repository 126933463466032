// pages/Login.js
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import styles from '../styles/Login.module.css';
import { useUser } from '../context/UserContext';

export default function Login() {
  const { login } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      setErrorMessage("Please fill out all fields");
      return;
    }
  
    const { success, message, dest } = await login(email.toLowerCase(), password);
    
    if (success) {
      setErrorMessage("");
      setSuccessMessage("Login successful! Redirecting...");
      setTimeout(() => {
        navigate(dest);
      }, 2000);
    } else {
      setErrorMessage(message); // Display error message from login attempt
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginBox}>
        <h1 className={styles.heading}>Login</h1>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
        <input
          className={styles.inputField}
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className={styles.inputField}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className={styles.loginButton} onClick={handleLogin}>
          Login
        </button>
        <Link to="/forgot-password" className={styles.forgotPasswordLink}>
          Forgot Password?
        </Link>
        <h1 className={styles.heading}>Or</h1>
        <Link to="/sign-up" className={styles.signUpButton}>
          Sign Up
        </Link>
      </div>
    </div>
  );
}


