// FindTalent.js
import React, { useState } from 'react';
import styles from '../styles/FindTalent.module.css';
import findCandidatePhoto from '../images/FindCandidate.svg';
import IndustriesSection from '../components/IndustriesSection';

export default function FindTalent() {
  const [imageLoaded, setImageLoaded] = useState(false);

    return (
      <div className={styles.container}>
        {/* Placeholder or Loading Effect */}
        {!imageLoaded && <div className={styles.imagePlaceholder}></div>}

        {/* Image with onLoad Event */}
        <img 
            src={findCandidatePhoto} 
            alt="Staff" 
            className={`${styles.image} ${imageLoaded ? styles.imageVisible : styles.imageLoading}`} 
            onLoad={() => setImageLoaded(true)}
        />
        
        <IndustriesSection context="find-talent"/>
        <div className={styles.industryIntro}>
            <h2 className={styles.industriesWeServeText}>Find Your Next Employee With Apex Staff Global</h2>
            <div className={styles.textButtonContainer}>
              <p className={styles.industriesParagraph}>In an industry bustling with emerging companies, Apex Staff Global distinguishes itself through its innovative and efficient HR solutions. As a premier staffing agency, we focus on providing the optimal match between candidates and businesses to foster growth, productivity, and success.<br/><br></br>Apex Staff Global takes immense pride in selecting the perfect candidate tailored to the unique environment of each business, providing an enriching experience for our clients. Our commitment to quality and specialization sets us apart, as we stride to place candidates in the most fitting roles.<br/><br></br>Whether you're a contractor set on augmenting your workforce, or a corporation aiming to find the ideal match for an interim or long-term commitment, we offer the requisite insights and resources to assist you wherever you are across the United States. Apex Staff Global is here to trailblaze paths in the staffing realm with its fresh, solution-focused approach.</p>
              {/* <button className={styles.contactUsButton}>Contact us</button> */}
            </div>
        </div>
      </div>
    );
  }