import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/check-session`, { 
          withCredentials: true
        });
        if (response.status === 200) {
          setUser(response.data.user);
        }
      } catch (error) {
        console.error(error);
        setUser(null);
      } finally {
        setLoading(false); // Set loading to false after fetching user data
      }
    };

    initializeUser();
  }, []);

  const login = async (email, password) => {
    let dest = '/find-job';
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`, {
        email, password
      }, {
        withCredentials: true
      });
  
      if (response.status === 200) {
        setUser(response.data);
        if (response.data.role === 'admin') dest = '/admin';
        return { success: true, 'dest': dest};
      }
    } catch (error) {
      console.error('Login failed', error);
      return { success: false, message: error.response?.data?.error || 'Incorrect email or password' }; // Provide error message
    }
  };

  const logout = async (navigate) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {}, { withCredentials: true });
    setUser(null);
    navigate('/');
  };

  const isAdmin = user?.role === 'admin';

  return (
    <UserContext.Provider value={{ user, loading, login, logout, isAdmin, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
