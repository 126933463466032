import React from 'react';
import { useUser } from '../../context/UserContext.js'; 
import styles from '../../styles/component-styles/Industry-Jobs.module.css'; 
import SignUpButton from '../../components/Buttons/SignUpButton.js';

const ItTechJobs = () => {
    const { user } = useUser(); 

    const renderButton = () => {
        if (user) {
            return <button className={styles.button} onClick={() => {/* Logic to handle application */}}>Apply</button>;
        } else {
            return <SignUpButton />;
        }
    };

    return (
        <div className={styles.jobsContainer}>
            <h2>Find Your Next IT & Tech Job with Apex Staff Global</h2>
            <p>At Apex Staff Global, we specialize in connecting skilled professionals with their next opportunity in the IT and Tech industry. Whether you're a network administrator, data scientist, or IT technician, our platform offers a wide range of job listings tailored to meet your expertise and career goals.</p>
            <p>Partnering with Apex Staff Global provides you with:</p>
            <ul>
                <li>A dedicated team committed to finding tailored job opportunities for you in the IT and Tech sector.</li>
                <li>Expert guidance and personalized support throughout your job search journey.</li>
                <li>Access to cutting-edge tools and resources to enhance your technical skills and application process.</li>
            </ul>
            <p>Don't miss out on your next great opportunity in the dynamic IT and Tech field. Contact us today!</p>
            {/* Render the appropriate button based on the user's authentication status */}
            {renderButton()}
        </div>
    );
};

export default ItTechJobs;