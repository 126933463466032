// SignUpButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/component-styles/button-styles/SignUpButton.module.css'

const SignUpButton = () => {
    const navigate = useNavigate();

    const handleSignUpClick = () => {
        navigate('/sign-up');
    };

    return (
        <button className={styles.button} onClick={handleSignUpClick}>Sign Up</button>
    );
};

export default SignUpButton;
