// SalesRep.js
import React, { useState } from 'react';
import styles from '../styles/SalesRep.module.css';
import axios from 'axios';

export default function SalesRep() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    resume: null
  });
  const [showModal, setShowModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading status

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      const file = files[0];
      if (!file) return;

      const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!validTypes.includes(file.type)) {
        setFeedbackMessage("Only PDF and Word document files are allowed.");
        setFormData({ ...formData, resume: null });
        return;
      }

      if (file.size > 5242880) {
        setFeedbackMessage("File size should be less than 5MB.");
        return;
      }

      setFeedbackMessage("");
      setFormData({ ...formData, resume: file });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.resume) {
      setFeedbackMessage("Please upload a valid file before submitting.");
      return;
    }

    setIsLoading(true); // Set loading to true before the request

    const data = new FormData();
    data.append('firstName', formData.firstName);
    data.append('lastName', formData.lastName);
    data.append('email', formData.email);
    data.append('resume', formData.resume);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/email/sales-rep-apply`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const result = response.data;
      setFeedbackMessage(result.message);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting application:', error);
      setFeedbackMessage('Failed to submit application');
    } finally {
      setIsLoading(false); // Set loading to false after the request
    }
  };

  return (
    <div className={styles.jobPosting}>
      <div className={styles.header}>
        <h1>Sales Representative</h1>
        <div className={styles.headerActions}>
          <button className={styles.saveButton}>Save</button>
          <button className={styles.applyButton} onClick={() => setShowModal(true)}>Apply now</button>
        </div>
      </div>

      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.closeButton} onClick={() => { setShowModal(false); setIsSubmitted(false); setFeedbackMessage(''); setIsLoading(false); }}>&times;</span>
            {isLoading ? (
              <div className={styles.loadingMessage}>
                <h2>Submitting Application...</h2>
              </div>
            ) : isSubmitted ? (
              <div className={styles.successMessage}>
                <h2>Success!</h2>
                <p>{feedbackMessage}</p>
              </div>
            ) : (
              <>
                <h2>Quick Apply to this Position:</h2>
                {feedbackMessage && <div className={styles.feedbackMessage}>{feedbackMessage}</div>}
                <form onSubmit={handleSubmit}>
                  <div className={styles.nameFormGroup}>
                    <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} required />
                    <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} required />
                  </div>
                  <input type="email" name="email" placeholder="Your Email" onChange={handleChange} required />
                  <div className='resume-upload'>
                    <h4>Resume:</h4>
                    <input type="file" name="resume" onChange={handleChange} required />
                  </div>
                  <button type="submit" className={styles.applyButton}>Submit Application</button>
                </form>
              </>
            )}
          </div>
        </div>
      )}

      <div className={styles.section} style={styles.overviewSection}>
        <h2>Overview</h2>
        <p><strong>Location:</strong> Remote</p>
        <p><strong>Salary:</strong> High Ticket Commission</p>
        <p><strong>Type:</strong> Full Time</p>
        <p><strong>Schedule:</strong> Flexible Monday - Friday between (9:00am – 9:00pm CST)</p>
      </div>

      <div className={styles.section}>
        <h2>About Us</h2>
        <p>Located in the Great city of San Antonio, Texas, Apex Staff Global stands as a leader redefining excellence in talent acquisition. With a steadfast commitment to industry expertise, we specialize in employing advanced tactics to ensure unparalleled precision in matching candidates with their ideal roles. Our dedicated team of professionals understands the importance of securing the perfect fit for every position, alleviating the burden for companies and allowing them to focus on their core tasks with unwavering confidence.</p>
      </div>

      <div className={styles.section}>
        <h2>Details</h2>
        <h3>Responsibilities</h3>
        <ul>
          <li>As a Sales Representative, you will be responsible for developing and maintaining client relationships through various communication channels, including phone, email, and in-person interactions, leveraging leads and business opportunities provided by Apex.</li>
          <li>Manage both inbound and outbound business-to-business sales calls effectively.</li>
          <li>Routinely follow up with current and prospective clients to maintain and grow relationships.</li>
          <li>Accurately document all interactions and activities with client accounts.</li>
          <li>Work with internal teams to create and implement sales strategies aimed at achieving sales goals.</li>
          <li>Conduct research to identify potential customers and stay informed about industry trends.</li>
          <li>Develop and sustain strong relationships with new and existing customers.</li>
          <li>Prepare and deliver compelling sales presentations to potential clients.</li>
          <li>Negotiate contracts and pricing agreements with customers to close deals.</li>
          <li>Provide exceptional customer service and support throughout the sales process.</li>
          <li>Manage and fulfill customer orders, ensuring a smooth and efficient process from order placement to delivery.</li>
        </ul>

        <h3>Skills</h3>
        <ul>
          <li>Strong sales and negotiation skills</li>
          <li>Proven track record in business development and achieving sales targets</li>
          <li>Excellent communication and interpersonal skills</li>
          <li>Ability to work independently and as part of a team</li>
          <li>Strong problem-solving and decision-making abilities</li>
          <li>Experience in Microsoft Office Suite is preferred</li>
          <li>Experience in Salesforce is a plus</li>
          <li>Bilingual in English and Spanish is a plus</li>
        </ul>

        <h3>What We Offer</h3>
        <p>This role offers a highly competitive commission structure, enhanced by incentive markup plans designed to further reward exceptional performance. Apex provides your book of business, leads, company phone number, and programs to ensure you have the resources needed to succeed.</p>
        <p>Training will be provided, but we highly value a proactive attitude and a willingness to learn and adapt, as these incentives are crafted to motivate and recognize individuals who demonstrate exceptional dedication and results.</p>
        <p>If you are a motivated individual with a passion for sales, we want to hear from you!</p>
        <p>Join our team as a Sales Representative and elevate your career.</p>
        <p>Off Weekends and Federal Holidays (11)</p>
      </div>

      <div className={styles.applySection}>
        <button className={styles.applyButton} onClick={() => setShowModal(true)}>Apply now</button>
      </div>

    </div>
  );
}
