import React, { useState } from 'react';
import styles from '../styles/SalesRep.module.css';
import axios from 'axios';

export default function ProjectManagerIntern() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        resume: null
    });
    const [showModal, setShowModal] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'resume') {
            const file = files[0];
            if (!file) return;

            // Check file type (allow PDFs and Word documents)
            const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            if (!validTypes.includes(file.type)) {
                setFeedbackMessage("Only PDF and Word document files are allowed.");
                setFormData({ ...formData, resume: null });
                return; // Stop the function here to prevent state update
            }

            // Check file size (e.g., less than 5MB)
            if (file.size > 5242880) {
                setFeedbackMessage("File size should be less than 5MB.");
                return; // Stop the function here to prevent state update
            }

            setFeedbackMessage(""); // Clear the feedback message
            // If validation passes, update the state with the file
            setFormData({ ...formData, resume: file });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the resume is still null, which means validation failed
        if (!formData.resume) {
            setFeedbackMessage("Please upload a valid file before submitting.");
            return; // Prevent form submission
        }

        setIsLoading(true); // Set loading to true before the request

        const data = new FormData();
        data.append('firstName', formData.firstName);
        data.append('lastName', formData.lastName);
        data.append('email', formData.email);
        data.append('resume', formData.resume);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/email/project-manager-intern-apply`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const result = response.data;
            setFeedbackMessage(result.message);
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting application:', error);
            setFeedbackMessage('Failed to submit application');
        } finally {
            setIsLoading(false); // Set loading to false after the request
        }
    };

    return (
        <div className={styles.jobPosting}>
            <div className={styles.header}>
                <h1>Project Manager Intern</h1>
                <div className={styles.headerActions}>
                    <button className={styles.saveButton}>Save</button>
                    <button className={styles.applyButton} onClick={() => setShowModal(true)}>Apply now</button>
                </div>
            </div>

            {showModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <span className={styles.closeButton} onClick={() => { setShowModal(false); setIsSubmitted(false); setFeedbackMessage(''); setIsLoading(false); }}>&times;</span>
                        {isLoading ? (
                            <div className={styles.loadingMessage}>
                                <h2>Submitting Application...</h2>
                            </div>
                        ) : isSubmitted ? (
                            <div className={styles.successMessage}>
                                <h2>Success!</h2>
                                <p>{feedbackMessage}</p>
                            </div>
                        ) : (
                            <>
                                <h2>Quick Apply to this Position:</h2>
                                {feedbackMessage && <div className={styles.feedbackMessage}>{feedbackMessage}</div>}
                                <form onSubmit={handleSubmit}>
                                    <div className={styles.nameFormGroup}>
                                        <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} required />
                                        <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} required />
                                    </div>
                                    <input type="email" name="email" placeholder="Your Email" onChange={handleChange} required />
                                    <div className='resume-upload'>
                                        <h4>Resume:</h4>
                                        <input type="file" name="resume" onChange={handleChange} required />
                                    </div>
                                    <button type="submit" className={styles.applyButton}>Submit Application</button>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            )}

            <div className={styles.section} style={styles.overviewSection}>
                <h2>Overview</h2>
                <p><strong>Location:</strong> Remote</p>
                <p><strong>Salary:</strong> High Ticket Commission</p>
                <p><strong>Type:</strong> Internship</p>
                <p><strong>Schedule:</strong> Flexible</p>
            </div>

            <div className={styles.section}>
                <h2>Details</h2>
                <h3>Position Overview</h3>
                <p>Join Apex Staff Global for a dynamic 3-month internship designed to provide hands-on experience in project management within a national company. This program offers comprehensive training, equipping you with the skills needed to excel in project management roles. The schedule is flexible to accommodate your needs.</p>

                <h3>Key Highlights</h3>
                <ul>
                    <li><strong>Experience:</strong> Work closely with experienced managers and contribute to real-world projects, gaining invaluable insights into the industry.</li>
                    <li><strong>Training:</strong> Receive all necessary training during the internship to build a strong foundation in project management.</li>
                    <li><strong>Performance-Based Advancement:</strong> Exceptional interns have the opportunity to transition into a full-time Project Manager role at Apex Staff Global, with increased responsibilities and significant salary enhancements.</li>
                    <li><strong>Professional Growth:</strong> Develop your project management skills, enhance your resume, and build a professional network within the company.</li>
                </ul>

                <h3>What We Offer</h3>
                <ul>
                    <li><strong>Mentorship:</strong> Work under the guidance of seasoned professionals who will support your development and growth.</li>
                    <li><strong>Career Path:</strong> Potential for a permanent position with the company, contingent on performance during the internship.</li>
                    <li><strong>Skill Development:</strong> Gain practical experience in managing projects, collaborating with teams, and delivering successful outcomes.</li>
                    <li><strong>Flexible Schedule:</strong> Enjoy a flexible schedule that allows you to balance your internship with other commitments.</li>
                </ul>

                <h3>Who We Are Looking For</h3>
                <ul>
                    <li><strong>Ambitious Individuals:</strong> Self-motivated and eager to learn, with a strong interest in project management.</li>
                    <li><strong>Team Players:</strong> Able to collaborate effectively with diverse teams.</li>
                    <li><strong>Problem Solvers:</strong> Capable of handling challenges and finding innovative solutions.</li>
                </ul>

                <p>This internship is a unique opportunity to jumpstart your career in project management with a reputable national company. Apply now to become a part of Apex Staff Global and take the first step towards a rewarding career!</p>

            </div>
            <div className={styles.applySection}>
                <button className={styles.applyButton} onClick={() => setShowModal(true)}>Apply now</button>
            </div>
        </div>
    );
}