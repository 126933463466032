import React from 'react';
import styles from '../../styles/component-styles/Industry-Jobs.module.css';
import ContactUsButton from '../../components/Buttons/ContactUsButton.js';

const TechTalent = () => {
    return (
        <div className={styles.jobsContainer}>
            <h2>Find Elite IT & Tech Talent for Your Company with Apex Staff Global.</h2>
            <p>At Apex Staff Global, we are dedicated to linking innovative companies with top-tier IT and technology professionals. From cutting-edge startups to established tech giants, our platform provides access to a wide-ranging pool of skilled individuals in various tech disciplines. Whether you’re looking for a visionary CTO, an agile project manager, or a team of developers to lead your next project, our network is rich with potential candidates ready to drive your business forward.</p>
            <p>Engaging with Apex Staff Global benefits your company through:</p>
            <ul>
                <li>A committed team focused on understanding your technical needs and finding the right talent to meet those challenges.</li>
                <li>Expert guidance and comprehensive support throughout your recruitment journey, ensuring a smooth and efficient hiring process.</li>
                <li>Exclusive access to tools and resources that enhance your visibility and engagement with leading candidates in the tech field.</li>
            </ul>
            <p>Empower your technology initiatives by securing a strategic addition to your team. Explore our premier network of IT and tech professionals today!</p>
            <ContactUsButton />
        </div>
    );
};

export default TechTalent;
