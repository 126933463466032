// Footer.js
import styles from '../styles/Footer.module.css'
export default function Footer() {
  return (
    <footer className={styles["site-footer"]}>
      <div className={styles["container"]}>
        <div className={styles["contact-info"]}>
          Contact us by <a href="/contact-us">EMAIL</a> or call <a href="tel:1-210-664-0022">1-210-664-0022</a>
        </div>
        <div className={styles["footer-links"]}>
          <span>Copyright © 2023 Apex Staff Global - All Rights Reserved. | 7550 I-10 Suite 800, San Antonio, TX 78229 | <a href="/careers">Careers</a></span>
        </div>
      </div>
    </footer>
  );
}

