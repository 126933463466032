import React, { useState } from 'react';
import axios from 'axios';
import styles from '../styles/PostJob.module.css';
import { useUser } from '../context/UserContext';

const PostJob = () => {
    const { user } = useUser();
    const [formData, setFormData] = useState({
        title: '',
        location: '',
        salary: '',
        type: 'Full-Time',
        schedule: 'Flexible Monday - Friday between (9:00am – 9:00pm CST)',
        description: '',
        responsibilities: '',
        skills: '',
        aboutUs: '',
        whatWeOffer: '',
        industry: '',
        _id: user?._id,
    });
    const [jobPosted, setJobPosted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/jobs/create-job`, formData);
            setJobPosted(true);
        } catch (error) {
            alert('Error posting job');
        }
    };

    const toDashboard = () => {
        window.location.href = '/admin/dashboard';
    };

    if (jobPosted) {
        return (
            <div className={styles.postJobContainer}>
                <div className={styles.successMessage}>
                    <h2>Job posted successfully!</h2>
                    <button onClick={toDashboard}>Back to Dashboard</button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.postJobContainer}>
            <h1 className={styles.headerText}>Post a Job</h1>
            <form onSubmit={handleSubmit}>
                <div className={styles.sectionContainer}>
                    <h2>Job Overview</h2>
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="location">Location</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="industry">Industry</label>
                    <select
                        id="industry"
                        name="industry"
                        value={formData.industry}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select an industry</option>
                        <option value="Business">Business</option>
                        <option value="Construction">Construction</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="ItTech">IT and Tech</option>
                        <option value ="Drivers">Drivers</option>
                    </select>

                    <label htmlFor="salary">Salary</label>
                    <input
                        type="text"
                        id="salary"
                        name="salary"
                        value={formData.salary}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="type">Type</label>
                    <select
                        id="type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        required
                    >
                        <option value="Full-Time">Full-Time</option>
                        <option value="Part-Time">Part-Time</option>
                        <option value="Contract">Contract</option>
                        <option value="Internship">Internship</option>
                        <option value="Temporary">Temporary</option>
                    </select>
                    <label htmlFor="schedule">Schedule</label>
                    <input
                        type="text"
                        id="schedule"
                        name="schedule"
                        value={formData.schedule}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.sectionContainer}>
                    <h2>Job Details</h2>
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <label htmlFor="responsibilities">Responsibilities</label>
                    <textarea
                        id="responsibilities"
                        name="responsibilities"
                        value={formData.responsibilities}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <label htmlFor="skills">Skills</label>
                    <textarea
                        id="skills"
                        name="skills"
                        value={formData.skills}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <div className={styles.sectionContainer}>
                    <h2>Additional Information (Each section is optional)</h2>
                    <label htmlFor="aboutUs">About Us</label>
                    <textarea
                        id="aboutUs"
                        name="aboutUs"
                        value={formData.aboutUs}
                        onChange={handleChange}
                    ></textarea>
                    <label htmlFor="whatWeOffer">What We Offer</label>
                    <textarea
                        id="whatWeOffer"
                        name="whatWeOffer"
                        value={formData.whatWeOffer}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <button type="submit">Post Job</button>
            </form>
        </div>
    );
};

export default PostJob;
