import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Careers.module.css';

const careers = [
    { title: 'Project Manager Intern', path: '/project-manager-internship', description: 'Join Apex Staff Global for a dynamic 3-month internship designed to provide hands-on experience in project management.' },
    { title: 'Sales Representative', path: '/sales-rep', description: 'Become a Sales Representative and develop and maintain client relationships through various communication channels.' },
    // Add more career opportunities here as needed
];

const Careers = () => {
    return (
        <div className={styles.careersContainer}>
            <h1>Careers at Apex Staff Global</h1>
            <p>Explore exciting career opportunities with Apex Staff Global. Join our team and contribute to our mission of connecting the world's professionals with opportunities that fulfill their career ambitions and contribute to the success of organizations worldwide.</p>
            <div className={styles.careersList}>
                {careers.map((career, index) => (
                    <div key={index} className={styles.careerCard}>
                        <h2>{career.title}</h2>
                        <p>{career.description}</p>
                        <Link to={career.path} className={styles.applyButton}>Learn More & Apply</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Careers;
