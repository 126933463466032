// src/pages/JobDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/JobDetail.module.css';
import QuickApplyModal from '../components/QuickApplyModal';

const JobDetail = () => {
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs/job/${jobId}`);
                setJob(response.data);
            } catch (error) {
                console.error('Error fetching job:', error);
            }
        };

        fetchJob();
    }, [jobId]);

    if (!job) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.jobPosting}>
            <div className={styles.header}>
                <h1>{job.overview.title}</h1>
                <div className={styles.headerActions}>
                    {/* <button className={styles.saveButton}>Save</button> */}
                    <button className={styles.applyButton} onClick={() => setShowModal(true)}>Apply now</button>
                </div>
            </div>

            <QuickApplyModal 
                showModal={showModal}
                setShowModal={setShowModal}
                apiUrl={`${process.env.REACT_APP_API_URL}/api/email/quick-apply`}
                jobDetails={{title: job.overview.title, id: jobId}}
            />

            <div className={styles.section} style={styles.overviewSection}>
                <h2>Overview</h2>
                <p><strong>Location:</strong> {job.overview.location}</p>
                <p><strong>Salary:</strong> {job.overview.salary}</p>
                <p><strong>Type:</strong> {job.overview.type}</p>
                <p><strong>Schedule:</strong> {job.overview.schedule}</p>
            </div>

            {job.aboutUs && (
                <div className={styles.section}>
                    <h2>About Us</h2>
                    <p className={styles.preWrapText}>{job.aboutUs}</p>
                </div>
            )}

            <div className={styles.section}>
                <h2>Description</h2>
                <p className={styles.preWrapText}>{job.details.description}</p>
            </div>

            <div className={styles.section}>
                <h2>Responsibilities</h2>
                <p className={styles.preWrapText}>{job.details.responsibilities}</p>
            </div>

            <div className={styles.section}>
                <h2>Skills</h2>
                <p className={styles.preWrapText}>{job.details.skills}</p>
            </div>


            {job.whatWeOffer && (
                <div className={styles.section}>
                    <h2>What We Offer</h2>
                    <p className={styles.preWrapText}>{job.whatWeOffer}</p>
                </div>
            )}

            <div className={styles.section}>
                <h2>Have Questions?</h2>
                <p className={styles.preWrapText}>Feel free to call our office at (210) 664-0022 for more information.</p>
            </div>

            <div className={styles.applySection}>
                <button className={styles.applyButton} onClick={() => setShowModal(true)}>Apply now</button>
            </div>
        </div>
    );
};

export default JobDetail;
