// ContactUs.js
import React, { useState } from 'react';
import axios from 'axios';
import styles from '../styles/ContactUs.module.css';

export default function ContactUs() {
    const [formData, setFormData] = useState({
        email: '',
        country: 'USA', // defaulting to USA since it is currently the only option
        purpose: '',
        positions: '',
        comments: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/email/contact-form`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    }

    if (isSubmitted) {
        return (
            <div className={styles.emailSentContainer}>
                <h2>Thank You!</h2>
                <p>Your email has been sent successfully. We will be in contact soon.</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.phoneContact}>
                <h2>Contact Us By Phone</h2>
                <p>1-210-664-0022</p>
                <p>*This number is available from 9AM - 5PM Central Time.</p>
            </div>
            <div className={styles.emailContact}>
                <h2>Contact Us By Email</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">Your Email Address:</label>
                    <input type="email" id="email" name="email" required onChange={handleChange} />

                    <label htmlFor="country">Country:</label>
                    <select id="country" name="country" required onChange={handleChange}>
                        <option value="usa">United States of America</option>
                    </select>

                    <label htmlFor="purpose">Purpose:</label>
                    <select id="purpose" name="purpose" required onChange={handleChange}>
                        <option value="">Select your purpose...</option>
                        <option value="Interested in Apex Staff Global's Services">Interested in Apex Staff Global's Services</option>
                        <option value="Employment Opportunities">Employment Opportunities</option>
                    </select>

                    <label htmlFor="positions">Positions Needed:</label>
                    <input type="text" id="positions" name="positions" required onChange={handleChange} />

                    <label htmlFor="comments">Requests/Comments:</label>
                    <textarea id="comments" name="comments" rows="4" onChange={handleChange}></textarea>
                    
                    <button type="submit" className={styles.submitBtn}>SEND EMAIL</button>
                </form>
            </div>
        </div>
    );
}
