import React, { createContext, useContext, useState } from 'react';

const NavbarContext = createContext();

export function useNavbar() {
  return useContext(NavbarContext);
}

export function NavbarProvider({ children }) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const toggleNav = () => {
    setIsNavExpanded((prev) => !prev);
  };

  const closeNav = () => {
    setIsNavExpanded(false);
  };

  return (
    <NavbarContext.Provider value={{ isNavExpanded, toggleNav, closeNav }}>
      {children}
    </NavbarContext.Provider>
  );
}
