import React from 'react';
import styles from '../../styles/component-styles/Industry-Jobs.module.css';
import ContactUsButton from '../../components/Buttons/ContactUsButton.js';

const HealthcareTalent = () => {
    return (
        <div className={styles.jobsContainer}>
            <h2>Connect with Exceptional Healthcare Talent through Apex Staff Global.</h2>
            <p>At Apex Staff Global, we understand the critical importance of finding dedicated and skilled professionals in the healthcare sector. Our platform is designed to bridge healthcare institutions with top talent, ranging from experienced medical practitioners to innovative healthcare administrators. Whether your need is for a specialist doctor, a nursing leader, or a healthcare IT expert, our extensive network ensures you have access to candidates who can meet the diverse challenges of today's healthcare landscape.</p>
            <p>Partnering with Apex Staff Global offers your institution:</p>
            <ul>
                <li>A focused team adept at matching your specific healthcare staffing needs with the right talent.</li>
                <li>Professional guidance and support throughout the hiring process, from candidate search to final selection.</li>
                <li>Tools and resources to maximize your institution’s exposure and appeal to premier candidates in the healthcare field.</li>
            </ul>
            <p>Strengthen your healthcare team with strategic hires. Discover our elite network of healthcare professionals today!</p>
            <ContactUsButton />
        </div>
    );
};

export default HealthcareTalent;
