// SignUp.js
import { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/Signup.module.css';
import { useUser } from '../context/UserContext';

export default function SignUp() {
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState(''); // Add state for resume name
  const [uploadMessage, setUploadMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const fileInput = useRef(null);
  const navigate = useNavigate();
  const { login } = useUser();
  
  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setResume(file);
      setResumeName(file.name); // Set the resume name
      setUploadMessage(<span><strong>{file.name}</strong> uploaded successfully!</span>);
    }
  };

  const handleSignup = async () => {
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email.toLowerCase());
    formData.append('password', password);
    formData.append('resume', resume);
    formData.append('resumeName', resumeName); // Append resume name

    if (!firstName || !lastName || !email || !password) {
      setErrorMessage('All fields are required');
      return;
    } else if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    } else if (!validateEmail(email)){
      setErrorMessage('Invalid email');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
      });

      if (response.status === 200 && response.data.message === "User exists"){
        setErrorMessage("Email address already in use");
        return;
      }

      if (response.status === 201) {
        setErrorMessage("");
        setSuccessMessage("Signup successful! Redirecting...");
        setTimeout(() => {
          login(email, password);
          navigate('/find-job');
        }, 3000);
      }
    } catch (error) {
      setErrorMessage("Signup failed: " + error.response.data.error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.signupBox}>
        <h1 className={styles.heading}>Signup</h1>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <input 
          className={styles.inputField} 
          type="text" 
          placeholder="First Name"
          onChange={(e) => setFirstname(e.target.value)}
        />
        <input 
          className={styles.inputField} 
          type="text" 
          placeholder="Last Name"
          onChange={(e) => setLastName(e.target.value)}
        />
        <input 
          className={styles.inputField} 
          type="text" 
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
        />
        <input 
          className={styles.inputField} 
          type="password" 
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input 
          className={styles.inputField} 
          type="password" 
          placeholder="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <input 
          type="file"
          onChange={handleFileUpload}
          accept=".pdf, .doc, .docx"
          style={{ display: 'none' }}
          ref={fileInput}
        />
        <button className={styles.uploadButton} onClick={() => fileInput.current.click()}>Upload Resume</button>
        {uploadMessage && <p className={styles.uploadMessage}>{uploadMessage}</p>}
        {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
        <button className={styles.signupButton} onClick={handleSignup}>Signup</button>
        <h1 className={styles.heading}>Or</h1>
        <Link to="/login" className={styles.signUpButton}>Log in</Link>
      </div>
    </div>
  );
}
