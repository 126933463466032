// ContactUsButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/component-styles/button-styles/SignUpButton.module.css'

const ContactUsButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact-us');
    };

    return (
        <button className={styles.button} onClick={handleClick}>Contact Us</button>
    );
};

export default ContactUsButton;