// IndustriesSection.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/Industries.module.css';
import doctorImage from '../images/physician-medical-robe-with-stethoscope.webp';
import constructionImage from '../images/discussing-plan-factory.webp';
import techImage from '../images/hands-typing-laptop.webp';
import businessImage from '../images/shaking-hands.webp';
import driversImage from '../images/driving.webp';

const industries = [
    { title: 'Healthcare', image: doctorImage, path: 'healthcare' },
    { title: 'Construction', image: constructionImage, path: 'construction' },
    { title: 'IT & Tech', image: techImage, path: 'it-tech' },
    { title: 'Business', image: businessImage, path: 'business' },
    { title: 'Drivers', image: driversImage, path: 'drivers' },
];

const IndustriesSection = ({ context }) => {
    const navigate = useNavigate();
    const [loadedImages, setLoadedImages] = useState({});

    const handleImageLoad = (index) => {
        setLoadedImages((prevState) => ({
            ...prevState,
            [index]: true,
        }));
    };

    const handleCardClick = (path) => {
        navigate(`/${context}/${path}`);
    };

    return (
        <>
            {/* ... */}
            <div className={styles.cardContainer}>
                {industries.map((industry, index) => (
                    <div key={index} className={styles.card} onClick={() => handleCardClick(industry.path)}>
                        {!loadedImages[index] && <div className={styles.loadingSpinnerContainer}><div className={styles.loadingSpinner}></div></div>}
                        <img
                            src={industry.image}
                            alt={industry.title}
                            className={styles.cardImage}
                            onLoad={() => handleImageLoad(index)}
                            style={{ display: loadedImages[index] ? 'block' : 'none' }}
                        />
                        <div className={styles.cardTitle}>{industry.title}</div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default IndustriesSection;
