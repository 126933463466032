//pages/Industries.js
import React from 'react';
import styles from '../styles/Industries.module.css';

const industries = [
    { title: 'Healthcare', image: 'https://cdn.discordapp.com/attachments/1195112454817062942/1195112527227539618/healthcare-professional.png?ex=65b2ce46&is=65a05946&hm=860a69d24f15d143423d36a218ab7d49cf781bba1b2d47c5667cc9e8048793f1&' },
    { title: 'Construction', image: 'https://cdn.discordapp.com/attachments/1195112454817062942/1195112527718256711/orange-vest-construction-worker.png?ex=65b2ce46&is=65a05946&hm=81035d8132ae9cb93140db2e75f47f32ebff3e6a3ef9d1bcd457743485a0ee54&' },
    { title: 'IT & Tech', image: 'https://cdn.discordapp.com/attachments/1195112454817062942/1195113553183641670/tech-guy.png?ex=65b2cf3b&is=65a05a3b&hm=9df57215f861740a7f9dc15cc027de75a9831b2ee4e9c6fb871d177170c0cd90&' },
    { title: 'Executive', image: 'https://cdn.discordapp.com/attachments/1195112454817062942/1195116170802626650/business-woman.png?ex=65b2d1ab&is=65a05cab&hm=2d84c185d02b2f93d34a4fb7b309d13a9b7b8f786b573ba98d411dcf33d3e352&' },
];

export default function Industries() {
    return (
        <div className={styles.container}>
            <h1>Find Your Next Opportunity</h1>
            <p>At [Your Company Name], we specialize in connecting top talent with leading companies across various industries. Whether you're seeking your next career challenge or looking to fill a critical role in your organization, we're here to help.</p>
            
            <div className={styles.industryIntro}>
                <h2>Industries We Serve</h2>
                <p>Explore the diverse range of industries we cater to. Our expertise in these sectors ensures we can find the perfect match for your skills and professional aspirations.</p>
            </div>

            <div className={styles.cardContainer}>
                {industries.map((industry, index) => (
                    <div key={index} className={styles.card}>
                        <img src={industry.image} alt={industry.title} className={styles.cardImage}/>
                        <div className={styles.cardTitle}>{industry.title}</div>
                    </div>
                ))}
            </div>

            <div className={styles.connectSection}>
                <h2>Connect with the Best</h2>
                <p>Our extensive network in these industries allows us to offer unique and exciting opportunities. We understand the importance of the right fit for both employers and job seekers, ensuring a harmonious and productive work environment.</p>
            </div>

            <div className={styles.commitmentSection}>
                <h2>Our Commitment</h2>
                <p>We are committed to providing personalized and comprehensive support throughout the recruitment process. Our team of experts is dedicated to understanding your needs and aspirations to facilitate the perfect professional pairing.</p>
            </div>
        </div>
    );
}

