// AboutUs.js
import styles from '../styles/AboutUs.module.css'
export default function AboutUs() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>About Us</h1>
          <p><strong>Welcome to Apex Staff Global – Your Partner in Global Talent Solutions</strong></p>
          <p>At Apex Staff Global, our mission is to connect the world's professionals with opportunities that not only fulfill their career ambitions but also contribute to the success of organizations worldwide. With years of experience in the staffing industry, we have established ourselves as a trusted partner for both businesses seeking talent and professionals seeking their next career move.</p>
          <h3>Our Journey</h3>
          <p>Founded in a modest office, Apex Staff Global has now grown into an international staffing powerhouse. Our journey began with a simple yet profound belief: the right person in the right job can change lives and transform businesses. This belief has been the cornerstone of our approach, enabling us to build lasting relationships with top-tier organizations and talented professionals across various industries.</p>
          <h3>What We Do</h3>
          <p>Apex Staff Global specializes in providing exceptional talent solutions across a range of sectors including IT, healthcare, finance, engineering, and more. We offer a spectrum of services - from permanent placements to executive search and workforce management solutions. Our global reach and local expertise allow us to source the best talent from different parts of the world, ensuring a perfect fit for your organization's culture and goals.</p>
          <h3>Our Approach</h3>
          <p>Our success lies in our personalized approach. We take the time to understand the unique needs of each client and candidate. Our team of expert consultants employs the latest in recruitment technology and industry insights to deliver results that exceed expectations. We don't just fill positions; we forge partnerships and nurture careers.</p>
          <h3>Commitment to Excellence</h3>
          <p>At Apex Staff Global, we are committed to upholding the highest standards of professionalism and ethical practices. We are dedicated to creating a positive impact on the businesses we serve and the lives of the individuals we place. Our goal is to be more than just a staffing agency – we aim to be a catalyst for growth and success.</p>
          <h3>Join Us</h3>
          <p>Whether you are a business in need of talented individuals or a professional seeking new opportunities, Apex Staff Global is here to assist you in achieving your ambitions. Connect with us today and experience the Apex difference – where talent meets opportunity.</p>
        </div>
      </div>
    );
  }