// FindTalentRoutes.js
import { Route, Routes } from 'react-router-dom';
import FindTalent from '../pages/FindTalent';
import BusinessTalent from '../pages/Talent-Industries/BusinessTalent';
import HealthcareTalent from '../pages/Talent-Industries/HealthcareTalent'; 
import ConstructionTalent from '../pages/Talent-Industries/ConstructionTalent'; 
import ItTechTalent from '../pages/Talent-Industries/ItTechTalent'; 
import DriversTalent from '../pages/Talent-Industries/DriversTalent';
const FindTalentRoutes = () => {
  return (
    <Routes>
      <Route index element={<FindTalent />} />
      <Route path="construction" element={<ConstructionTalent />} />
      <Route path="healthcare" element={<HealthcareTalent />} />
      <Route path="it-tech" element={<ItTechTalent />} />
      <Route path="business" element={<BusinessTalent />} />
      <Route path="drivers" element={<DriversTalent />} />
    </Routes>
  );
};

export { FindTalentRoutes };
