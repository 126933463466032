import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/AllJobs.module.css';

const AllJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [jobToDelete, setJobToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs/jobs`);
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleDelete = async (jobId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/jobs/job/${jobId}`);
      setJobs(jobs.filter(job => job._id !== jobId));
      setJobToDelete(null); // Reset the job to delete
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>All Job Listings</h1>
      <div className={styles.jobList}>
        {jobs.map((job, index) => (
          <div key={index} className={styles.jobItem}>
            <Link to={`/jobs/${job._id}`} className={styles.jobLink}>
              <h3 className={styles.jobTitle}>{job.overview.title}</h3>
              <p className={styles.jobLocation}>{job.overview.location}</p>
              <p className={styles.jobSalary}>{job.overview.salary}</p>
              <p className={styles.jobType}>{job.overview.type}</p>
              <p className={styles.jobSchedule}>{job.overview.schedule}</p>
              <p className={styles.jobIndustry}>{job.overview.industry}</p>
            </Link>
            <div className={styles.jobActions}>
              <button onClick={() => navigate(`/edit-job/${job._id}`)}>Edit</button>
              <button onClick={() => setJobToDelete(job._id)}>Delete</button>
            </div>
            {jobToDelete === job._id && (
              <div className={styles.confirmDeleteOverlay}>
                <div className={styles.confirmDelete}>
                  <p>Are you sure you want to delete this job?</p>
                  <button className={styles.confirmButton} onClick={() => handleDelete(job._id)}>Confirm Delete</button>
                  <button className={styles.cancelButton} onClick={() => setJobToDelete(null)}>Cancel</button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllJobs;
