import React from 'react';
import { useUser } from '../../context/UserContext.js'; 
import styles from '../../styles/component-styles/Industry-Jobs.module.css'; 
import SignUpButton from '../../components/Buttons/SignUpButton.js';

const ConstructionJobs = () => {
    const { user } = useUser(); 

    const renderButton = () => {
        if (user) {
            return <button className={styles.button} onClick={() => {/* Logic to handle application */}}>Apply</button>;
        } else {
            return <SignUpButton />;
        }
    };

    return (
        <div className={styles.jobsContainer}>
            <h2>Find Your Next Construction Job with Apex Staff Global</h2>
            <p>At Apex Staff Global, we specialize in connecting skilled professionals with their next opportunity in the construction industry. Whether you're just starting your career or you're a seasoned veteran, our platform offers a wide range of job listings tailored to meet your needs. From residential to commercial projects, our extensive network of employers is looking for talented individuals like you.</p>
            <p>Signing up with Apex Staff Global gives you access to:</p>
            <ul>
                <li>A dedicated team committed to finding tailored job opportunities for you.</li>
                <li>Expert guidance and personalized support throughout your job search journey.</li>
                <li>Tools and resources to enhance your job search and application process.</li>
            </ul>
            <p>Don't miss out on your next great opportunity. Join our community of construction professionals today!</p>
            {/* Render the appropriate button based on the user's authentication status */}
            {renderButton()}
        </div>
    );
};

export default ConstructionJobs;
