import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../styles/EditJob.module.css';

const EditJob = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    overview: {
      title: '',
      location: '',
      salary: '',
      type: 'Full-Time',
      schedule: '',
      industry: '',
    },
    details: {
      description: '',
      responsibilities: '',
      skills: '',
    },
    aboutUs: '',
    whatWeOffer: '',
  });

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs/job/${jobId}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching job:', error);
      }
    };

    fetchJob();
  }, [jobId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');
    if (keys.length > 1) {
      setFormData((prevState) => ({
        ...prevState,
        [keys[0]]: {
          ...prevState[keys[0]],
          [keys[1]]: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/jobs/job/${jobId}`, formData);
      navigate('/admin/all-jobs');
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  return (
    <div className={styles.editJobContainer}>
      <div className={styles.header}>
        <h1>Edit Job</h1>
        <button className={styles.backButton} onClick={() => navigate(-1)}>Back</button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.sectionContainer}>
          <h2>Job Overview</h2>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="overview.title"
            value={formData.overview.title}
            onChange={handleChange}
            required
          />
          <label htmlFor="location">Location</label>
          <input
            type="text"
            id="location"
            name="overview.location"
            value={formData.overview.location}
            onChange={handleChange}
            required
          />
          <label htmlFor="industry">Industry</label>
          <select
            id="industry"
            name="overview.industry"
            value={formData.overview.industry}
            onChange={handleChange}
            required
          >
            <option value="">Select an industry</option>
            <option value="Business">Business</option>
            <option value="Construction">Construction</option>
            <option value="Healthcare">Healthcare</option>
            <option value="ItTech">IT and Tech</option>
          </select>

          <label htmlFor="salary">Salary</label>
          <input
            type="text"
            id="salary"
            name="overview.salary"
            value={formData.overview.salary}
            onChange={handleChange}
            required
          />
          <label htmlFor="type">Type</label>
          <select
            id="type"
            name="overview.type"
            value={formData.overview.type}
            onChange={handleChange}
            required
          >
            <option value="Full-Time">Full-Time</option>
            <option value="Part-Time">Part-Time</option>
            <option value="Contract">Contract</option>
            <option value="Internship">Internship</option>
            <option value="Temporary">Temporary</option>
          </select>
          <label htmlFor="schedule">Schedule</label>
          <input
            type="text"
            id="schedule"
            name="overview.schedule"
            value={formData.overview.schedule}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.sectionContainer}>
          <h2>Job Details</h2>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="details.description"
            value={formData.details.description}
            onChange={handleChange}
            required
          ></textarea>
          <label htmlFor="responsibilities">Responsibilities</label>
          <textarea
            id="responsibilities"
            name="details.responsibilities"
            value={formData.details.responsibilities}
            onChange={handleChange}
            required
          ></textarea>
          <label htmlFor="skills">Skills</label>
          <textarea
            id="skills"
            name="details.skills"
            value={formData.details.skills}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className={styles.sectionContainer}>
          <h2>Additional Information (Each section is optional)</h2>
          <label htmlFor="aboutUs">About Us</label>
          <textarea
            id="aboutUs"
            name="aboutUs"
            value={formData.aboutUs}
            onChange={handleChange}
          ></textarea>
          <label htmlFor="whatWeOffer">What We Offer</label>
          <textarea
            id="whatWeOffer"
            name="whatWeOffer"
            value={formData.whatWeOffer}
            onChange={handleChange}
          ></textarea>
        </div>
        <button type="submit">Update Job</button>
      </form>
    </div>
  );
};

export default EditJob;
