import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../asg-logo.png';
import styles from '../styles/Navbar.module.css';
import { useUser } from '../context/UserContext';
import { useNavbar } from '../context/NavbarContext';

export default function NavBar() {
  const { user, logout } = useUser();
  const { isNavExpanded, toggleNav, closeNav } = useNavbar();
  const navigate = useNavigate();

  const handleMenuItemClick = () => {
    closeNav();
  };

  const handleLogout = () => {
    logout(navigate);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles['logo-to-menu']}>
        <Link to="/" className={styles['navbar-logo']} onClick={handleMenuItemClick}>
          <img src={logo} alt="Logo" />
        </Link>

        <button className={styles['menu-toggle']} onClick={() => toggleNav()}>
          ☰ {/* Hamburger icon or use an SVG/icon library */}
        </button>

              {/* <div className={`${styles['search-container']} ${isNavExpanded ? styles['show'] : ''}`}>
        <input type="text" placeholder="Search Keywords" className={styles['search-input']} />
        <input type="text" placeholder="Search Locations" className={styles['search-input']} />
        <button className={styles['search-button']}>Search</button>
      </div> */}
      </div>

      <div className={`${styles['navbar-links']} ${isNavExpanded ? styles['show'] : ''}`}>
        <Link to="/find-job" className={styles['navbar-link']} onClick={handleMenuItemClick}>FIND A JOB</Link>
        <Link to="/find-talent" className={styles['navbar-link']} onClick={handleMenuItemClick}>FIND TALENT</Link>
        <Link to="/about-us" className={styles['navbar-link']} onClick={handleMenuItemClick}>ABOUT US</Link>
        {user ? (
          <>
            {user.role === 'admin' && (
              <Link to="/admin" className={styles['navbar-link']} onClick={handleMenuItemClick}>ADMIN DASHBOARD</Link>
            )}
            <Link to="/profile" className={styles['navbar-link']} onClick={handleMenuItemClick}>PROFILE</Link>
            <button className={styles['logoutButton']} onClick={() => { handleLogout(); handleMenuItemClick(); }}>LOGOUT</button>
          </>
        ) : (
          <Link to="/login" className={styles['navbar-link']} onClick={handleMenuItemClick}>LOGIN</Link>
        )}
      </div>
    </nav>
  );
}
