// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';
import axios from 'axios';
import styles from '../styles/Profile.module.css';

const Profile = () => {
    const { user, setUser } = useUser();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        resume: null,
        resumeName: '' // Add resumeName to formData
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state

    useEffect(() => {
        if (user) {
            setFormData({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                resume: user.resume || null,
                resumeName: user.resume?.name || '' // Set resumeName from user data
            });
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'resume') {
            setFormData({ ...formData, resume: files[0], resumeName: files[0].name }); // Set resumeName
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData();
        form.append('firstName', formData.firstName);
        form.append('lastName', formData.lastName);
        form.append('email', formData.email);
        if (formData.resume) {
            form.append('resume', formData.resume);
            form.append('resumeName', formData.resumeName); // Append resumeName
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/profile`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });
            setUser(response.data.user);
            alert('Profile updated successfully');
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('Failed to update profile');
        }
    };

    const handleViewResume = async () => {
        setIsLoading(true); // Set loading state to true
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/resume`, {
                responseType: 'blob',
                withCredentials: true,
            });
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error fetching resume:', error);
            alert('Failed to fetch resume');
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    return (
        <div className={styles.profileContainer}>
            <h2>Profile</h2>
            {isEditing ? (
                <form onSubmit={handleSubmit}>
                    <div className={styles.formGroup}>
                        <label>First Name</label>
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Last Name</label>
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Email</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Resume</label>
                        <input type="file" name="resume" onChange={handleChange} />
                    </div>
                    <button type="submit" className={styles.updateButton}>Update Profile</button>
                    <button type="button" onClick={() => setIsEditing(false)} className={styles.cancelButton}>Cancel</button>
                </form>
            ) : (
                <div>
                    <p><strong>First Name:</strong> {formData.firstName}</p>
                    <p><strong>Last Name:</strong> {formData.lastName}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Resume:</strong> {formData.resumeName ? (
                        formData.resumeName.endsWith('.pdf') ? (
                            <button onClick={handleViewResume} className={styles.resumeLink}>
                                {isLoading ? 'Opening in new tab...' : formData.resumeName}
                            </button>
                        ) : (
                            formData.resumeName
                        )
                    ) : 'No resume uploaded'}</p> {/* Display resumeName */}
                    <button onClick={() => setIsEditing(true)} className={styles.editButton}>Edit Profile</button>
                </div>
            )}
        </div>
    );
};

export default Profile;
