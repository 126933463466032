import React, { useState } from 'react';
import axios from 'axios';
import styles from '../styles/component-styles/QuickApplyModal.module.css';

const QuickApplyModal = ({ showModal, setShowModal, apiUrl, jobDetails }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        resume: null
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'resume') {
            const file = files[0];
            if (!file) return;

            const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            if (!validTypes.includes(file.type)) {
                setFeedbackMessage("Only PDF and Word document files are allowed.");
                setFormData({ ...formData, resume: null });
                return;
            }

            if (file.size > 5242880) {
                setFeedbackMessage("File size should be less than 5MB.");
                return;
            }

            setFeedbackMessage("");
            setFormData({ ...formData, resume: file });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.resume) {
            setFeedbackMessage("Please upload a valid file before submitting.");
            return;
        }

        setIsLoading(true);

        const data = new FormData();
        data.append('firstName', formData.firstName);
        data.append('lastName', formData.lastName);
        data.append('email', formData.email);
        data.append('resume', formData.resume);
        data.append('jobTitle', jobDetails.title); // Additional job-related data
        data.append('jobId', jobDetails.id);
        data.append('jobLink', `${window.location.origin}/jobs/${jobDetails.id}`);

        try {
            const response = await axios.post(apiUrl, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const result = response.data;
            setFeedbackMessage(result.message);
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting application:', error);
            setFeedbackMessage('Failed to submit application');
        } finally {
            setIsLoading(false);
        }
    };

    if (!showModal) {
        return null;
    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <span className={styles.closeButton} onClick={() => { setShowModal(false); setIsSubmitted(false); setFeedbackMessage(''); setIsLoading(false); }}>&times;</span>
                {isLoading ? (
                    <div className={styles.loadingMessage}>
                        <h2>Submitting Application...</h2>
                    </div>
                ) : isSubmitted ? (
                    <div className={styles.successMessage}>
                        <h2>Success!</h2>
                        <p>{feedbackMessage}</p>
                    </div>
                ) : (
                    <>
                        <h2>Quick Apply to this Position:</h2>
                        {feedbackMessage && <div className={styles.feedbackMessage}>{feedbackMessage}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className={styles.nameFormGroup}>
                                <input type="text" name="firstName" placeholder="First Name" required onChange={handleChange} />
                                <input type="text" name="lastName" placeholder="Last Name" required onChange={handleChange} />
                            </div>
                            <input type="email" name="email" placeholder="Your Email" required onChange={handleChange} />
                            <div className='resume-upload'>
                                <h4>Resume:</h4>
                                <input type="file" name="resume" required onChange={handleChange} />
                            </div>
                            <button type="submit" className={styles.applyButton}>Submit Application</button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default QuickApplyModal;
